
import Vue from "vue";
import { ApplicationActions } from "../store";
import { Dispatcher, Committer } from "vuex-type-helper";
import { Autorisatie } from "../Autorisatie";

export default Vue.extend({
  props: {
    title: String
  },
  data() {
    return {
      Autorisatie: Autorisatie
    };
  },
  computed: {
    navIsDashboard(): boolean {
      return this.$route.name === "leads";
    },
    navIsReports(): boolean {
      return (
        this.hasAutorisatie(Autorisatie.RapportageLeads) ||
        this.hasAutorisatie(Autorisatie.RapportageOrders)
      );
    },
    navIsAdmin(): boolean {
      return this.$store.getters.isAdmin;
    },
    navIsOrderDashboard(): boolean {
      return this.$route.name === "orders";
    },
    importeur(): string | null {
      return this.$store.getters.importeurNaam;
    },
    hasImporteur(): boolean {
      return !!this.importeur;
    },
    iconPath(): string {
      return this.hasImporteur
        ? "/images/icon" + this.importeur + ".png"
        : "images/icon.png";
    },
    otherLang(): string {
      if (this.$i18n.locale === "nl") return "English";

      return "Nederlands";
    }
  },
  methods: {
    hasAutorisatie(autorisatie: number): boolean {
      return this.$store.getters.hasAutorisatie(autorisatie);
    },
    switchLang() {
      const newLocale = this.$i18n.locale === "nl" ? "en" : "nl";
      this.$store.dispatch<Dispatcher<ApplicationActions>>({
        type: "setLocale",
        locale: newLocale
      });
    }
  }
});
