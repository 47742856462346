
import Vue from "vue";
import Pageheader from "./components/Pageheader.vue";
import InlineStyleLoader from "./components/InlineStyleLoader.vue";
import Notifications from "./components/Notifications.vue";
import { Dispatcher } from "vuex-type-helper";
import { ApplicationActions } from "./store";
import includes from "lodash-es/includes";
import { Autorisatie } from "./Autorisatie";

export default Vue.extend({
  components: {
    Pageheader,
    Notifications,
    InlineStyleLoader
  },
  data() {
    return {
      Autorisatie: Autorisatie
    };
  },
  computed: {
    importeur(): string | null {
      return this.$store.getters.importeurNaam;
    },
    locale(): string | null {
      return this.$store.getters.locale;
    },
    hasImporteur(): boolean {
      return !!this.importeur;
    },
    showViewport(): boolean {
      return (
        includes(location.host, "-test.vijzelmolen") ||
        includes(location.host, "localhost")
      );
    }
  },
  created() {
    this.$store.dispatch<Dispatcher<ApplicationActions>>({
      type: "initStore"
    });
  },
  methods: {
    hasAutorisatie(autorisatie: number): boolean {
      return this.$store.getters.hasAutorisatie(autorisatie);
    },

    setFavicon(url: string) {
      const removeFavicon = () => {
        let links = document.getElementsByTagName("link");
        let head = document.getElementsByTagName("head")[0];
        for (let i = 0; i < links.length; i++) {
          if (links[i].getAttribute("rel") === "icon") {
            head.removeChild(links[i]);
          }
        }
      };

      removeFavicon();

      let link = document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "icon";
      link.href = url;
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  },
  watch: {
    importeur: function(val) {
      if (!!val) this.setFavicon("favicon" + val + ".ico");
    },
    locale: function(locale) {
      if (!!locale) this.$i18n.locale = locale;
    }
  }
});
